import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import Home from "./views/Home/Home";
import EmailVerify from "./views/EmailVerify/EmailVerify";
import Wallet from "./views/Wallet/Wallet";
import BuyCoin from "./views/BuyCoin/BuyCoin";
import Deposit from "./views/Deposit/Deposit";
import TransactionHistory from "./views/TransactionHistory/TransactionHistory";
import MyContract from "./views/MyContract/MyContract";
import MyContractDetails from "./views/MyContractDetails/MyContractDetails";
import HostingHistory from "./views/HostingHistory/HostingHistory";
import Arbitrage from "./views/Arbitrage/Arbitrage";
import JoinArbitrage from "./views/JoinArbitrage/JoinArbitrage";
import Mining from "./views/Mining/Mining";
import MiningDetails from "./views/MiningDetails/MiningDetails";
import ArbitrageDetails from "./views/ArbitrageDetails/ArbitrageDetails";
import Loan from "./views/Loan/Loan";
import Notification from "./views/Notification/Notification";
import Chat from "./views/Chat/Chat";
import Referral from "./views/Referral/Referral";
import CommonChat from "./views/Chat/CommonChat";
import LoanHistory from "./views/LoadnHistory/LoanHistory";

import { ToastContainer } from "react-toastify";
import { useCustomizeContext } from "./Services/Context";

import { useGetRequest, usePostRequest } from "./Services/Queries";

import USER_APIS from "./Services/APIS";
import { QueryKeys } from "./Services/QueryKeys";

import DisableDevtool from "disable-devtool";
import ScaleLoader from "react-spinners/ScaleLoader";
import Web3 from "web3";
import { toast } from "react-toastify";
import ProtectRoute from "./views/PrivateRoute/PrivateRoute";

import Landing from "./views/Landing/Landing";
import Withdrawrequest from "./views/Deposit/Withdrawreq";


const App = (props) => {
  const web3 = new Web3(window.ethereum);

  DisableDevtool({ url: "https://www.google.com/" });

  const {
    siteInformation: { setCopyRightsContent },
    walletInfo: { setWalletAddress },
    loaderProperty: { loading, setLoading },
    userInfo: { setAuthToken },
  } = useCustomizeContext();

  let requestData = {
    Api: USER_APIS.GET_SITE_SETTINGS,
    Query_Key: QueryKeys?.GET_SITE_DETAILS_KEY,
  };

  let { mutateAsync: walletConnect } = usePostRequest(
    QueryKeys?.WALLET_CONNECT_KEY
  );

  let { data } = useGetRequest(requestData);
  if (data?.status) {
    setCopyRightsContent(data?.data?.Copyrightstext);
    document.getElementById("titleID").innerText = data?.data?.SiteName;
    document.getElementById("favId").setAttribute("href", data?.data?.favicon);
  }

  useEffect(() => {


    if (
      localStorage.getItem("accountNumber") &&
      localStorage.getItem("crypto.com-access-key")
    ) {
      setWalletAddress(localStorage.getItem("accountNumber"));
      setAuthToken(localStorage.getItem("crypto.com-access-key"));
    }

    else {
      connectingMetaMask();
      // if (!localStorage.getItem("deviceModel")) {
      //   handleRightClick()
      //   DisableDevtool({ url: "https://www.google.com/" });
      // }

    }
  }, []);

  const connectingMetaMask = async () => {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      try {
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        let payload = {
          address: accounts[0],
          referralCode: "",
        };

        let postObj = {
          Api: USER_APIS?.WALLET_CONNECTION,
          Payload: payload,
          Type: "",
        };
        let res = await walletConnect(postObj);
        if (res?.status === true) {
          setWalletAddress(accounts[0]);
          localStorage.setItem("accountNumber", accounts[0]);
          localStorage.setItem("isWalletConnected", true);
          localStorage.setItem("crypto.com-access-key", res?.data);
          localStorage.setItem("chatToken", res?.ugehjfgui);
          localStorage.setItem("deviceModel", res?.sgdfgh);

          setAuthToken(res?.data);

          localStorage.removeItem("userstatus")

          setLoading(false);
        }

        else if (res?.status === false) {
          localStorage.setItem("userstatus", res?.userAccStatus)
          setWalletAddress(accounts[0]);
          localStorage.setItem("accountNumber", accounts[0]);
          localStorage.setItem("isWalletConnected", true);
          localStorage.setItem("crypto.com-access-key", res?.data);
          localStorage.setItem("chatToken", res?.ugehjfgui);
          localStorage.setItem("deviceModel", res?.sgdfgh);
          setAuthToken(res?.data);
          setLoading(true)

        }
        return true;
      } catch (e) {
        return false;
      }
    } else {
      //toast.error("Please Add Metamask Extension !");
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        connectingMetaMask();
      });
    }
  }, []);


  return (
    <React.Fragment>


      <div className={loading ? "loading" : "d-none"}>
        <ScaleLoader
          color="#084ded"
          height="50"
          width="6"
          speedMultiplier="2"
        />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <BrowserRouter>

        <Routes history={props.history}>
          <Route path="/" element={<Home />} />
          <Route path="/emailverify" element={<EmailVerify />} />
          <Route path="/wallet" element={<ProtectRoute />}>
            <Route index element={<Wallet />} />
          </Route>

          <Route path="/entrust/:id" element={<ProtectRoute />}>
            <Route index element={<BuyCoin />} />
          </Route>

          <Route path="/deposit/:id" element={<ProtectRoute />}>
            <Route index element={<Deposit />} />
          </Route>

          <Route path="/transactionhistory/:id" element={<ProtectRoute />}>
            <Route index element={<TransactionHistory />} />
          </Route>

          <Route path="/mycontract/:id" element={<ProtectRoute />}>
            <Route index element={<MyContract />} />
          </Route>

          <Route
            path="/mycontractdetails/:id"
            element={<MyContractDetails />}
          />
          <Route path="/hostinghistory" element={<HostingHistory />} />
          <Route path="/arbitrage" element={<ProtectRoute />}>
            <Route index element={<Arbitrage />} />
          </Route>
          <Route path="/joinarbitrage" element={<JoinArbitrage />} />
          <Route path="/mining" element={<ProtectRoute />}>
            <Route index element={<Mining />} />
          </Route>

          <Route path="/ardetails" element={<ProtectRoute />}>
            <Route index element={<MiningDetails />} />
          </Route>

          <Route path="/miningdetails" element={<ArbitrageDetails />} />
          <Route path="/loan" element={<ProtectRoute />}>
            <Route index element={<Loan />} />
          </Route>

          <Route path="/notification" element={<ProtectRoute />}>
            <Route index element={<Notification />} />
          </Route>

          <Route path="/WithdrawMessage" element={<ProtectRoute />}>
            <Route index element={<Withdrawrequest />} />
          </Route>

          {localStorage.getItem("userstatus") == "Deactive" ? <Route path="/chat" element={<Chat />} /> :
            <Route path="/chat" element={<ProtectRoute />}>
              <Route index element={<Chat />} />
            </Route>
          }

          <Route path="/referral" element={<Referral />} />

          <Route path="/loanhistory" element={<ProtectRoute />}>
            <Route index element={<LoanHistory />} />
          </Route>
          <Route path="/landing" element={<Landing />} />
        </Routes>
        <CommonChat />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;


